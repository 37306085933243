(function($) {
    $(document).ready(function() {


        // Mini Menu
        if ($('.submenu').length) { 

            var stickysub = new Waypoint.Sticky({
                element: $('.submenu')[0],
                offset: -50
            });
            $('main').waypoint(function(direction) {
                if (direction === 'down') {
                    $('.wrapper-hero').addClass('miniTitle'),
                    $('.submenu').animate({ top: "0" })
                } else {
                    $('.wrapper-hero').removeClass('miniTitle'),
                    $('.submenu').animate({ top: "-150px" }, function() {
                        $(this).css('top', '')
                    }),
                    $('header').removeClass("miniMenu"),
                    $('.submenu .icon-close').toggleClass('icon-menu icon-close')
                }
            }, {
                offset: -75
            });
            $('.submenu').on({
                mouseenter: function() {
                    $(this).addClass('select'),
                    $(this).children('a').not('.active').addClass('option'),
                    $(this).children('li').not('.active').children('a').addClass('option')
                },
                mouseleave: function() {
                    $(this).removeClass('select'),
                    $(this).find('a').removeClass('option')
                }
            }, '.tab,.toc.link ul');
            $(".submenu").on("click", '.icon-menu, .icon-close', function() {
                $(this).toggleClass('icon-menu icon-close'),
                $(this).parents('body').children('header').toggleClass("miniMenu")
            });
            $(".submenu").on("click", '.toc.link ul', function() {
                $(this).removeClass('select'),
                $(this).find('a').removeClass('option'),
                $(this).parents('.submenu').children('.icon-close').toggleClass('icon-menu icon-close'),
                $(this).parents('body').children('header.miniMenu').removeClass("miniMenu")
            });
        };


        // lite TOC
        if ($('.apPage .toc_lite').length) {  

            $('.apPage .toc_lite').on('mouseenter', '.owl-carousel>li>a', function() {
                $(this).parents('.toc_lite').find('.owl-carousel>li>a').removeClass('open'),
                $(this).parents('.toc_lite').find('.owl-carousel>li>.sub').fadeOut(0),
                $(this).addClass('open'),
                $(this).siblings('.sub').fadeIn(0)
            });

            $('.apPage .toc_lite').on('mouseleave', function() {
                $(this).find('.owl-carousel>li>a').removeClass('open'),
                $(this).find('.owl-carousel>li>.sub').fadeOut(0)
            });

        };       


        // Owl Carousel (right column)
        $('.owl-carousel.rxArt').owlCarousel({
            items: 1,
            margin: 10,
            loop: true,
            nav: true,
            navText: ["<span class='icon-arrow-left2'></span>", "<span class='icon-arrow-right2'></span>"],
            dots: false,
            lazyLoad: true
        });


        // Quiz Mini Menu
        if ($('#quiz-wrapper').length) {

            $('body').children('.wrapper-hero.quiz').find('.hero h1').clone().prependTo('#quiz-wrapper .quiz-status');

            $(window).scroll(function() {
                if ($(window).scrollTop() > 500) {
                    $('#quizWrapper').find('.quiz-status').fadeIn(200)             
                } else {
                    $('#quizWrapper').find('.quiz-status').fadeOut(300)           
                }
            });

        };


        // Slideshows 
        if ($('#accordion').length) {
            $('#accordion').on('click', 'h3', function() {
                $(".fold").not($(this).next()).slideUp(300);
                $(this).next().slideToggle(600, function(){
                    $('body, html').animate({
                        scrollTop: $(this).offset().top - 55
                    }, 300)
                })
            })
        };


        // Search
        if ($('#search').length) {
            var $SearchList = $(".searchresults");
            $SearchList.each(function() {
                var $Wrapper = $(this).find("ul");
                if ($Wrapper.height() < 750) {
                    $Wrapper.css("height", "auto");
                    $(this).find(".more,.gradient").css("display", "none")
                } else {
                    $Wrapper.css("height", "750px");
                    $(this).find(".more").children("span").on("click", function() {
                        var $ParWrapper = $(this).parents('.searchresults').find('ul'),
                            $CurHeight = $ParWrapper.height();
                        if ($CurHeight == 750) {
                            $ParWrapper.css('height', 'auto');
                            $ParWrapper.children(".gradient").fadeOut();
                            $(this).addClass('active')
                        } else {
                            $ParWrapper.animate({
                                height: 750
                            }, 200);
                            $ParWrapper.children(".gradient").fadeIn();
                            $(this).removeClass('active');
                            $('body, html').stop().animate({
                                scrollTop: $(this).parents('.searchresults').offset().top 
                            }, 600)
                        };
                        return false
                    })
                }
            })
        };


        // article
        $('#refs').on('click', function () {
            $(this).next().slideToggle()
        });

        
        // ForumCenter
        if ($('#ForumCenter_fmt').length) {
            var $IndexList = $(".sideBox[class$=List]");
            $IndexList.each(function() {
                var $Wrapper = $(this).find(".wrapper");
                if ($Wrapper.height() < 500) {
                    $Wrapper.css("height", "auto");
                    $(this).find(".more,.gradient").css("display", "none")
                } else {
                    $Wrapper.css("height", "500px");
                    $(this).find(".more").children("span").on("click", function() {
                        var $ParWrapper = $(this).parents('.sideBox').find('.wrapper'),
                            $CurHeight = $ParWrapper.height();
                        if ($CurHeight == 500) {
                            $ParWrapper.css('height', 'auto');
                            $ParWrapper.children(".gradient").fadeOut();
                            $(this).addClass('active')
                        } else {
                            $ParWrapper.animate({
                                height: 500
                            }, 200);
                            $ParWrapper.children(".gradient").fadeIn();
                            $(this).removeClass('active');
                            $('body, html').stop().animate({
                                scrollTop: $(this).parents('.sideBox').offset().top - 100
                            }, 600)
                        };
                        return false
                    })
                }
            });
        };





    });
})(jQuery);