(function($) {
    $(document).ready(function() {

        // Sticky Top AD
        function stickyTopAd() {
            if (((document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop) > 100) {
                $('.adTopWrapper').addClass('fixed');
                $('header').addClass('fixed');
            } else {
                $('.adTopWrapper').removeClass('fixed');
                $('header').removeClass('fixed');
            }
        }
        function disableStickyTopAd() {
            window.removeEventListener('scroll', stickyTopAd);
            $('.adTopWrapper').removeClass('fixed');
            $('header').removeClass('fixed');
        }
        // Enable sticky Top Ad 
        window.addEventListener('scroll', stickyTopAd);
        // Disable sticky top Ad 5 seconds after it's rendered.
        try {
            if (googletag.pubadsReady) {
                googletag.pubads().addEventListener('slotRenderEnded', function(event) {
                    if (event.slot.getSlotElementId() === 'ads2-pos-101') {
                        setTimeout(disableStickyTopAd, 5000);
                    }
                });
            }
        } catch (e) {
            console.log('googletag eventlistener exception:', e);
        }
        // Check default browser scroll position for sticky top Ad enabling.
        stickyTopAd();
        // Disable sticky top Ad after 10 seconds if google ad event is not captured.
        setTimeout(disableStickyTopAd, 10000);


        // Masterhead
        $('.masterhead .search').on('click', 'button', function() {
            $(this).parents('body').toggleClass('freeze');
            $(this).parents('.masterhead').find('.menu span, .socialWrapper').fadeToggle();
            $(this).children().toggleClass('icon-close icon-search');
            $(this).parents('.masterhead').siblings('.searchBar').toggleClass('on')           
        });
        $('.masterhead .menu').on('click', 'span', function() {
            $(this).parents('body').toggleClass('freeze');
            $(this).parents('.masterhead').find('.search button, .socialWrapper').fadeToggle();
            $(this).toggleClass('icon-close icon-menu');
            $(this).parents('.masterhead').siblings('.masterSub').toggleClass('on')
        });
        $('.masterSub .stack').hover(function() {
            $(this).addClass('on');
            }, function() {
            $(this).removeClass('on');
        })


        // Back to Top
        $(window).scroll(function() {
            if ($(window).scrollTop() > 1000) {
                $('#backTop').fadeIn();
                $('#backTop').on('click', function() {
                    $('body, html').scrollTop(0)
                })
            } else {
                $('#backTop').fadeOut()
            }
        });


    });
})(jQuery);


// function to toggle visibility 
function toggle(targetId, signId) {
    if (document.getElementById) {
        target = document.getElementById(targetId);
        if (signId != '') { sign = document.getElementById(signId); }
        if (target.style.display == "none") {
            target.style.display = "";
            if (sign) { sign.src = sign.src.replace('plusSign', 'minusSign'); }
        } else {
            target.style.display = "none";
            if (sign) { sign.src = sign.src.replace('minusSign', 'plusSign'); }
        }
    }
}