(function($) {
    $(document).ready(function() {        


    // Top Drug Names (HP)
    $('.topDrug .az').on('click', 'li a', function() {
        var $curLetter= $(this).attr('id');
        $(this).parents('.az').find('li a').removeClass('active');
        $(this).parents('.topDrug').find('.azList ul').fadeOut(250);
        $(this).addClass('active');
        $(this).parents('.topDrug').find('.azList #list-' + $curLetter).fadeIn(250)
    });
    

    });
})(jQuery);
